<template>
  <div>
    <div v-if="routeName == 'Trash' && emails.length > 0" class="et">
      You have {{ emails.length }} conversations in Trash.

      <CButton color="light" size="sm" variant="ghost" @click="emptyTrash">
        Empty Trash now
      </CButton>
    </div>

    <ul class="c-messages p-0 mb-0">
      <template v-for="(email, index) in emails">
        <InboxMessage v-bind="{ email, folder: routeName.toLowerCase(), label }" />
      </template>
    </ul>

    <p
      class="mb-0"
      v-if="!loading && emails.length == 0"
      v-html="emptyMessages[routeName.toLowerCase()]"
    ></p>

    <CElementCover :opacity="0.4" v-show="loading">
      <CSpinner size="5xl" grow color="primary" />
    </CElementCover>
  </div>
</template>

<script>
import InboxMessage from "./InboxMessage";

export default {
  name: "Inbox",
  components: {
    InboxMessage,
  },
  data() {
    return {
      emails: [],
      loading: false,
      emptyMessages: {
        inbox: "No new messages! When you receive a new message, it will appear here.",
        sent: "No sent messages! When you send a message, it will appear here.",
        starred: "No starred messages. Stars let you give messages a special status to make them easier to find. To star a message, click on the star outline beside any message or conversation.",
        trash: "No conversations in Trash.",
        label: `There are no messages with this label.`,
      },
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    label() {
      return this.$route.params.label;
    }
  },

  async mounted() {
    await Promise.all([this.getMessages()]);
  },

  methods: {
    async getMessages() {
      var params = this.label ? { label: this.label } : { folder: this.routeName.toLowerCase() };

      this.loading = true;
      await this.$axios
        .get(this.$backend.CRM_EMAILS.GET_ALL, { params })
        .then((response) => {
          this.emails = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async emptyTrash() {
      this.$confirm({
        title: "Confirm deleting messages",
        message: `⚠️ This action will affect all ${this.emails.length} messages in Trash. Are you sure you want to continue?`,
        button: { no: "No", yes: "Yes" },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            await this.$axios
              .delete(this.$backend.CRM_EMAILS.EMPTY_TRASH)
              .then((response) => {
                this.emails = [];
              })
              .finally(() => {
                this.loading = false;
              });
          }
        },
      });
    },
  },
};
</script>

<style>
.et {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  align-items: center;
  background-color: rgba(241, 243, 244, 0.871);
  border-radius: 4px;
  color: #5f6368;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4px 0 24px;
  min-height: 48px;
}
.et > button {
  font-family: Arial, Helvetica, sans-serif;
  color: #1a73e8;

  font-size: 0.875rem;
  font-weight: 700;
  height: 36px;
  margin-left: 8px;
}
</style>
