<template>
  <CLink
    :to="{ name: 'Message', params: { id: messageID, folder, label }, query: { user: email.user_id } }"
    :class="{ 'c-message-read': email.is_read }"
    class="c-message"
  >
    <div class="d-flex flex-column align-items-center mr-3" style="width: 60px;">
      <CImg
        :src="email.sender?.image_icon || email.sender?.gravatar"
        class="c-avatar-img"
        style="width: 40px; height: 40px"
        placeholderColor="lightgray"
      />

      <CBadge v-if="email.is_my_task" class="mt-2 badge-task"><CIcon name="cil-bolt" /> Your Task</CBadge>

      <CBadge class="my-2" :color="emailLabel.color">{{ emailLabel.label }}</CBadge>

      <span
        v-c-tooltip="{
          content: email.is_starred ? 'Starred' : 'Not starred',
        }"
        @click="(e) => toggleStarred(e)"
      >
        <CIcon name="cil-star" height="24px" :class="{ 'text-warning': email.is_starred }" />
      </span>
    </div>

    <div class="c-message-details w-100">
      <div class="c-message-headers">
        <div>
          <div class="c-message-headers-subject mb-2">
            <span class="mr-1" :class="{ 'font-weight-bold': !email.is_read }">{{ email.subject }}</span>
            <CBadge v-if="email.is_outgoing" color="light" class="mr-1" ><span class="text-info"><CIcon name="cil-send" /> Outgoing Email</span></CBadge>
            <CBadge v-if="email.is_trashed" color="light"><span class="text-danger"><CIcon name="cil-trash" /> Trashed</span></CBadge>
          </div>

          <div class="c-message-headers-from">
            <div v-if="email.is_outgoing">
              <CBadge class="badge-msg-from">TO</CBadge>
              <span class="ml-1 text-muted">&lt;{{ email.reply_to?.toString() }}&gt;</span>
            </div>
            <div v-else>
              <span :class="{ 'font-weight-bold': !email.is_read }">{{ email.sender?.first_name }}</span>
              <span class="text-muted">&lt;{{ email.from }}&gt;</span>
            </div>
          </div>

          <div class="c-message-headers-from" v-if="email.cc && email.cc.length > 0">
            <CBadge class="badge-msg-from">CC</CBadge>
            <span class="text-muted ml-1">{{ email.cc.join(', ') }}</span>
          </div>

          <div class="c-message-headers-from" v-if="email.restaurant">
            <CIcon name="cil-restaurant" />
            <span class="text-muted ml-1">{{ email.restaurant.restaurant_name }}</span>
          </div>
        </div>

        <div class="c-message-headers-date">
          {{ moment_created_at.calendar() }} ({{ moment_created_at.fromNow() }})
        </div>
      </div>

      <div class="c-message-body text-medium-emphasis small mt-2" v-html="email.plain_reply"></div>
    </div>
  </CLink>
</template>

<script>
export default {
  name: "InboxMessage",
  props: {
    email: {
      type: Object,
      default: () => ({}),
    },
    folder: {
      type: String,
      default: "Inbox",
    },
    label: {
      type: String,
      default: null,
    },
  },
  computed: {
    moment_created_at() {
      return this.moment(this.email.created_at);
    },
    emailLabel() {
      return this.$globalVar.emailLabels.find((el) => el.value === this.email.labels[0]);
    },
    messageID() {
      return this.email.message_id.match(/^([^@]*)@/)[1];
    },
  },

  methods: {
    toggleStarred(e) {
      e.preventDefault();
      this.email.is_starred = !this.email.is_starred;
      this.$axios
        .put(
          this.$backend.CRM_EMAILS.UPDATE_EMAIL.replace("{id}", this.email.id),
          { starred: this.email.is_starred }
        )
        .catch(() => {
          this.email.is_starred = !this.email.is_starred;
        });
    },
  },
};
</script>
